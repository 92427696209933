/* Additional Utilities */



/* Force an aspect ratio on an element */
.aspect-box:before {
    content: "";
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
}
.aspect-box:after {
    content: "";
    display: table;
    clear: both;
}

@responsive {
    .aspect-ratio-1-1:before  { padding-top: 100%; }
    .aspect-ratio-16-9:before { padding-top: 56.25%; }
    .aspect-ratio-3-2:before  { padding-top: 66.67%; }
    .aspect-ratio-5-2:before  { padding-top: 40%; }
    .aspect-ratio-6-2:before  { padding-top: 33.34%; }

    .full-bleed {
        width: 100vw;
        margin-left: 50%;
        transform: translateX(-50%);
    }
    .full-bleed-none {
        width: auto;
        margin-left: 0;
        transform: none;
    }

    .container-none {
        margin-right: 0;
        margin-left: 0;
        padding-right: 0;
        padding-left: 0;
    }



    .bg-gradient-bezier {
        background-image: linear-gradient(to bottom,
            hsla(0, 0%, 0%, 0) 0%,
            hsla(0, 0%, 0%, 0.003) 9.7%,
            hsla(0, 0%, 0%, 0.012) 18.2%,
            hsla(0, 0%, 0%, 0.028) 25.7%,
            hsla(0, 0%, 0%, 0.05) 32.2%,
            hsla(0, 0%, 0%, 0.078) 38.1%,
            hsla(0, 0%, 0%, 0.112) 43.6%,
            hsla(0, 0%, 0%, 0.153) 48.6%,
            hsla(0, 0%, 0%, 0.201) 53.6%,
            hsla(0, 0%, 0%, 0.255) 58.6%,
            hsla(0, 0%, 0%, 0.316) 63.9%,
            hsla(0, 0%, 0%, 0.383) 69.5%,
            hsla(0, 0%, 0%, 0.457) 75.8%,
            hsla(0, 0%, 0%, 0.538) 82.8%,
            hsla(0, 0%, 0%, 0.626) 90.8%,
            hsla(0, 0%, 0%, 0.72) 100%
        );
    }

    .force-last-space-4 > :last-child {
        border-right: 1rem transparent solid;
    }
    .remove-last-space-4 > :last-child {
        border-right: 0;
    }

    .caption-bottom { caption-side: bottom; }

    .scroll-margin-top-8 {
        scroll-margin-top: theme(width.8);
    }
}
