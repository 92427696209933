/*
 * Base
 */

/* Font Scale */
@screen xs {
    html { font-size: 1.125rem; }
}



/* Typefaces */
body { @apply font-prose; }
h1, h2, h3, h4, h5 { @apply font-medium font-title; }

.wf-active {
    .font-prose,
    body {
        @apply font-prose-wf;
    }
    .font-title,
    h1, h2, h3, h4, h5 {
        @apply font-title-wf;
    }
}



/* Links */
a,
a:visited { 
    color: inherit;
    text-decoration: none;
}
a:hover { text-decoration: underline; }
a.block-link { text-decoration: none; }



/* Text Styles */
.title-h1       { @apply text-xl; }
.title-h2       { @apply text-lg; }
.prose-body     { @apply text-base; }
.prose-meta     { @apply text-sm; }



/* Frills */
.quo    { margin-left: -0.24em; }
.dquo   { margin-left: -0.44em; }
.tquo   { margin-left: -0.68em; }



/* Rich Text */

/* purgecss start ignore */
.richtext {
    p,
    ul,
    ol,
    blockquote { @apply mt-0 mb-5; }
    *:last-child { margin-bottom: 0; }
    & + & { margin-top: theme(spacing.5); }
    a { color: theme(colors.red.800); }
    
    h3 {
        font-size: theme(fontSize.xl);
        margin-top: theme(spacing.10);
        margin-bottom: theme(spacing.8);
    }
    h4 { 
        font-size: theme(fontSize.lg);
        margin-top: theme(spacing.9);
        margin-bottom: theme(spacing.3);
    }
    h5 {
        margin-top: theme(spacing.6);
        margin-bottom: theme(spacing.2);
        text-transform: uppercase;
    }
    h6 {
        font-weight: theme(fontWeight.semibold);
        margin-bottom: theme(spacing.2);
    }
    h3:first-child,
    h4:first-child { margin-top: 0; }
    
    ol,
    ul    { padding-left: theme(spacing.4); }
    li    { margin-bottom: theme(spacing.1); }
    li li { margin-left: theme(spacing.1); }
    ol    { list-style-type: decimal; }
    ul    { list-style-type: disc; }
    li ul { list-style-type: circle; }
    ol ol { list-style-type: lower-alpha; }
    ol ol ol { list-style-type: lower-roman; }
    
    a { text-decoration: underline }
    a.no-underline { text-decoration: none }
    
    strong { font-weight: theme(fontWeight.semibold); }

    blockquote {
        margin-left: 0;
        padding-left: theme(spacing.5);
        border-left: solid theme(colors.gray.200) 5px;
        font-style: italic;
    }
}
/* purgecss end ignore */

.richtext-dark a,
.richtext-dark a:visited {
    color: theme(colors.red.200);
}

#site-labs .richtext a,
#site-labs .richtext a:visited {
    color: theme(colors.cyan.700);
}

.bullets-lg li {
    padding-left: 1.2em;
    background: url('../icons/list-bullet-big.svg') no-repeat left 0.17em;
    background-size: 1em 1em;
}
