.bg-stars {
    background-image: url('../icons/star.svg');
}

.bg-stars-box:before {
    content: "";
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
}
.bg-stars-box:after {
    content: "";
    display: table;
    clear: both;
}

@responsive {
    .bg-stars-10-4 { background-size: 10%; }
    .bg-stars-10-4:before  { padding-top: 40%; }
    .bg-stars-6-2  { background-size: 16.666%; }
    .bg-stars-6-2:before  { padding-top: 33.34%; }
}
