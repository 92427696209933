/* Base */
html { scroll-behavior: smooth; }



/* Overscroll & page colours */
html, body {
    background-color: theme(colors.gray.100);
}
html#site-squirrels:before {
    content: '';
    position: fixed;
    z-index: -1;
    right: 0;
    left: 0;
    top: -50%;
    bottom: 50%;
    background-color: theme(colors.gray.800);
}



/* Defaults */
#pjax-page {
    text: theme(colors.gray.900);
    background-color: theme(colors.white);
    position: relative;
    z-index: 0;
}
p { margin-top: 0; }



/* lazysizes auto width calculation */
picture img[data-sizes="auto"] { 
    display: block;
    width: 100%;
}

picture img.w-auto[data-sizes="auto"] { width: auto; }
