/* Layouts */

:root {
    --gap-x: theme(spacing.4);
    @screen md {
        --gap-x: theme(spacing.6);
    }
}

.layout-grid {
    @screen sm {
        display: grid;
        grid-template-columns: repeat(12, minmax(0, 1fr));
        column-gap: var(--gap-x);
    }
}

.layout-header {
    @screen sm { grid-column: 1 / 4 }
}

.layout-content {
    @screen sm {
        grid-column: 5 / -1;
        display: grid;
        grid-template-columns: repeat(8, minmax(0, 1fr));
        column-gap: var(--gap-x);
    }
    @screen md { grid-column: 5 / -1; }
}

.layout-full {
    @screen sm { grid-column: 1 / -1; }
}



.layout-hero {
    @screen sm {
        @apply container;
        display: grid;
        column-gap: var(--gap-x);
        grid-template-columns: 1.6fr 1fr;
        grid-template-areas:
            "title title"
            "cover intro";
    }
    @screen md {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto 1fr;
    }
}

.layout-hero-cover {
    @screen sm {
        grid-area: cover;
    }
}
.layout-hero-cover figure {
    max-width: 30rem;
    margin-left: auto;
    margin-right: auto;
    @screen sm {
        max-width: none;
        padding-top: 0 !important;
        margin-left: 0;
        margin-right: 0;
    }
}

/* Make sure there's spacing on top as well as on the sides */
@media (min-width: 34rem) {
    .layout-hero-cover figure {
        padding-top: theme(width.8); 
    }
}

.layout-hero-title {
    @apply container;
    @screen sm {
        @apply container-none;
        grid-area: title;
    }
}

.layout-hero-intro {
    @apply container;
    @screen sm {
        @apply container-none;
        grid-area: intro;
    }
}

/* Utility classes */
.layout-gap { gap: var(--gap-x); }



/* Masonry */

.masonry {
    list-style: none;
    padding: 0;
}
.masonry-item {
    margin-bottom: theme(space.2);
}

@screen sm {
    .masonry {
        display: flex;
        flex-wrap: wrap;
        margin: theme(space.-1);
    }

    .masonry:after {
        content: '';
        display: block;
        flex-grow: 10;
    }
    
    .masonry-item {
        --masonryHeight: 200px;
        margin: theme(space.1);
        flex: 1 0 auto;
        width: calc(var(--width) / var(--height) * var(--masonryHeight));
        min-height: var(--masonryHeight);
    }

    .masonry-item img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        vertical-align: bottom;
    }  
}

@screen md {
    .masonry-item { --masonryHeight: 275px; }
}


/* Collapse sidebar if there's no room */
.with-sidebar { overflow: hidden; }

.with-sidebar > * {
    display: flex;
    flex-wrap: wrap;
}

.with-sidebar > * > * { flex-grow: 1; }

.with-sidebar > * > :last-child {
    flex-basis: 0;
    flex-grow: 999;
    min-width: calc(50%);
}
